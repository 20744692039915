import PubfaCatLogo from '../img/publifarm-cat-logo.svg'
import IconSearch from '../img/search.svg'
import IconChiediAiuto from '../img/icon-chiedi-aiuto.svg'
import IconConosciAgbd from '../img/icon-conosci-agbd.svg'
import Icon5x1000 from '../img/icon-5x1000.svg'
import IconSostienici from '../img/icon-sostienici.svg'
import IconPartecipa from '../img/icon-partecipa.svg'
import Underline from '../img/underline.png'
import UnderlineW from '../img/underline-w.png'
import UnderlineY from '../img/underline-y.png'
import UnderlineG from '../img/underline-g.png'
import UnderlineO from '../img/underline-o.png'
import UnderlineLgW from '../img/underline-lg-w.svg'
import UnderlineLgG from '../img/underline-lg-g.svg'
import IconArrowLeft from '../img/icon-arrow-left.svg'
import IconArrowRight from '../img/icon-arrow-right.svg'
import IconArrowSquareRight from '../img/arrow-square-right.png'
import IconArrowSquareRightW from '../img/arrow-square-right-w.png'
import TimelineDivider from '../img/timeline-divider.png'
import CloseBtnIcon from '../img/x.svg'


/**
 * Export all the theme images
 */

const themeMedia = () => {
    newImage(PubfaCatLogo)
    newImage(IconSearch)
    newImage(IconChiediAiuto)
    newImage(IconConosciAgbd)
    newImage(Icon5x1000)
    newImage(IconSostienici)
    newImage(IconPartecipa)
    newImage(Underline)
    newImage(UnderlineW)
    newImage(UnderlineY)
    newImage(UnderlineG)
    newImage(UnderlineO)
    newImage(UnderlineLgW)
    newImage(UnderlineLgG)
    newImage(IconArrowLeft)
    newImage(IconArrowRight)
    newImage(IconArrowSquareRight)
    newImage(IconArrowSquareRightW)
    newImage(TimelineDivider)
    newImage(CloseBtnIcon)
}

/**
 * Compile image from src folder
 * 
 * @param Image source 
 */

const newImage = (source) => {
    const newImage = new Image()
    newImage.src = source
}

export default themeMedia