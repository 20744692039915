import themeMedia from './themeMedia'

themeMedia()

Promise.all(
    [
        import('./numberSectionAnimate'),
        import('./headerContacts'),
        import('./headerNavigator'),
        import('./headerHero'),
        import('./blockCardGallery'),
        import('./contactForm'),
        import('./contactMap'),
        import('./sectionAnimation')
    ]
).then(
    (
        [
            numberSectionAnimate,
            headerContacts,
            headerNavigator,
            headerHero,
            blockCardGallery,
            contactForm,
            contactMap,
            sectionAnimation
        ]
    ) => {
        numberSectionAnimate.default()
        headerContacts.default()
        headerNavigator.default()
        headerHero.default()
        blockCardGallery.default()
        contactForm.default()
        contactMap.default()
        sectionAnimation.default()
    }
)